import React from "react";
import Empty from "antd/es/empty";
import Descriptions from "antd/es/descriptions";
import { useIntl } from "react-intl";

export default function SettlementRules({ rental, contract, rentalShare }) {
  const intl = useIntl();

  return contract && rental ? (
    <div style={{ maxWidth: 750 }}>
      <br />
      <Descriptions
        title={`${intl.formatMessage({ id: "settlement-rules" })} ${
          rental.name
        }`}
        layout="horizontal"
        column={1}
        bordered
      >
        {contract.owners_take > 0 && (
          <Descriptions.Item
            label={intl.formatMessage({
              id:
                contract.owners_from === "GROSS"
                  ? "rules-owners-take-gross"
                  : "rules-owners-take-net",
            })}
          >
            {" "}
            {contract.owners_take * 100} %{" "}
          </Descriptions.Item>
        )}
        {contract.commission_ratio && (
          <Descriptions.Item
            label={intl.formatMessage({ id: "rules-commission-ratio" })}
          >
            {" "}
            {contract.commission_ratio * 100} %{" "}
          </Descriptions.Item>
        )}
        {contract.fixed_profit && contract.fixed_profit !== "0.00" && (
          <Descriptions.Item
            label={intl.formatMessage({ id: "rules-fixed-profit" })}
          >
            {" "}
            {contract.fixed_profit} PLN{" "}
          </Descriptions.Item>
        )}
        {contract.daily_profit && contract.daily_profit !== "0.00" && (
          <Descriptions.Item
            label={intl.formatMessage({ id: "rules-daily-profit" })}
          >
            {" "}
            {contract.daily_profit} PLN{" "}
          </Descriptions.Item>
        )}
        {contract.minimum_profit && contract.minimum_profit !== "0.00" && (
          <Descriptions.Item
            label={intl.formatMessage({ id: "rules-minimum-profit" })}
          >
            {" "}
            {contract.minimum_profit} PLN{" "}
          </Descriptions.Item>
        )}
        {rentalShare > 0 && rentalShare !== 1 && (
          <Descriptions.Item
            label={intl.formatMessage({ id: "rules-rental-share" })}
          >
            {" "}
            {rentalShare * 100} %{" "}
          </Descriptions.Item>
        )}
        {rental.settlement_period && (
          <Descriptions.Item
            label={intl.formatMessage({ id: "rules-settlement-period" })}
          >
            {" "}
            {rental.settlement_period === "MONTHLY"
              ? intl.formatMessage({ id: "rules-settlement-period-monthly" })
              : intl.formatMessage({
                  id: "rules-settlement-period-quarterly",
                })}{" "}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  ) : (
    <Empty
      className="empty-sign"
      description={intl.formatMessage({ id: "no-settlement-rules" })}
    />
  );
}
