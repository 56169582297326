import React from "react";
import { useIntl } from "react-intl";
import { sumByKey } from "./utils";

import Table from "antd/es/table";
import Empty from "antd/es/empty";

const { Column } = Table;

const dayjs = require("dayjs");

dayjs().format();

export default function CostTable({ costs }) {
  const intl = useIntl();

  const showInvoices =
    costs.filter(
      (cost) => cost.invoice_view_url && cost.invoice_view_url !== ""
    ).length > 0;
  return costs && costs.length !== 0 ? (
    <>
      <Table
        dataSource={costs}
        rowKey="id"
        size="small"
        pagination={false}
        summary={(pageData) => {
          const sums = [
            {
              sum: "-",
              key: "invoice_link",
            },
            {
              sum: sumByKey(pageData, "cost"),
              key: "costs",
            },
          ];

          if (!showInvoices) {
            sums.splice(0, 1);
          }

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <b>{intl.formatMessage({ id: "costs-sum" })}</b>
                </Table.Summary.Cell>
                {sums.map((sum) => (
                  <Table.Summary.Cell key={sum.key}>
                    <b>{sum.sum}</b>
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </>
          );
        }}
      >
        <Column
          title={intl.formatMessage({ id: "cost" })}
          dataIndex="description"
        />
        {showInvoices && (
          <Column
            title={intl.formatMessage({ id: "invoice" })}
            dataIndex="invoice_view_url"
            render={(value) => {
              if (value && value !== "") {
                return (
                  <a href={value} target="_blank" rel="noopener noreferrer">
                    {intl.formatMessage({ id: "preview" })}
                  </a>
                );
              }
              return value;
            }}
          />
        )}
        <Column
          title={intl.formatMessage({ id: "gross-cost-amount" })}
          dataIndex="cost"
        />
      </Table>
    </>
  ) : (
    <Empty
      className="empty-sign"
      description={intl.formatMessage({ id: "no-costs" })}
    />
  );
}
