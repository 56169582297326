import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import Header from "./Header";

const { REACT_APP_BRAND_NAME } = process.env;

export function LayoutTopSpacer({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: "white",
        margin: 0,
        padding: 0,
        borderRadius: 10,
        marginTop: 50,
      }}
    >
      {children}
    </div>
  );
}

function Layout({ isLoggedIn, filters, children, nav, renderLocaleSwitcher }) {
  const intl = useIntl();
  document.title = `${intl.formatMessage({
    id: "site-title",
  })} - ${REACT_APP_BRAND_NAME}`;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: 20,
      }}
    >
      <Header
        type="filters"
        isLoggedIn={isLoggedIn}
        nav={nav}
        renderLocaleSwitcher={renderLocaleSwitcher}
      />
      <div
        style={{
          overflowY: "hidden",
          overflowX: "auto",
        }}
      >
        {filters}
      </div>
      {children}
    </div>
  );
}

Layout.propTypes = {
  isLoggedIn: PropTypes.bool,
  filters: PropTypes.node,
};

Layout.defaultProps = {
  isLoggedIn: true,
  filters: null,
};

export default Layout;
