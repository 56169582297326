const COLORS = {
  Renters: "#f48232",
  "Rent like home": "#00a7e4",
  Apartview: "#ffc72c",
};

const white = "#ffffff";
const green = "#4aa96c";
const red = "#f55c47";
const gray = "#aaaaaa";
const blue = "#1e90ff";
const yellow = "#ffdf6b";

const LOGO_DIMENSIONS = {
  Renters: {
    height: 72,
    width: 72,
  },
  "Rent like home": {
    height: 30,
    width: 150,
  },
  Apartview: {
    height: 60,
    width: 150,
  },
};

const CALENDAR_COLORS = {
  Renters: {
    owner: blue,
    blocked: red,
    booking: green,
    available: white,
    default: white,
  },
  "Rent like home": {
    owner: yellow,
    blocked: gray,
    booking: red,
    available: green,
    default: red,
  },
  Apartview: {
    owner: yellow,
    blocked: gray,
    booking: red,
    available: green,
    default: red,
  },
};

const CUT_OFF = {
  Renters: {
    MONTHLY: {
      year: 2024,
      month: 6,
      from: "2024/06",
    },
    QUARTERLY: {
      year: 2024,
      month: 2,
      from: "2024/Q2",
    },
  },
  "Rent like home": {
    MONTHLY: {
      year: 2021,
      month: 4,
      from: "2021/04",
    },
    QUARTERLY: {
      year: 2024,
      month: 2,
      from: "2024/Q2",
    },
  },
  Apartview: {
    MONTHLY: {
      year: 2024,
      month: 4,
      from: "2024/04",
    },
    QUARTERLY: {
      year: 2024,
      month: 2,
      from: "2024/Q2",
    },
  },
};

const monthFilters = [
  {
    id: 1,
    formatted: "01",
  },
  {
    id: 2,
    formatted: "02",
  },
  {
    id: 3,
    formatted: "03",
  },
  {
    id: 4,
    formatted: "04",
  },
  {
    id: 5,
    formatted: "05",
  },
  {
    id: 6,
    formatted: "06",
  },
  {
    id: 7,
    formatted: "07",
  },
  {
    id: 8,
    formatted: "08",
  },
  {
    id: 9,
    formatted: "09",
  },
  {
    id: 10,
    formatted: "10",
  },
  {
    id: 11,
    formatted: "11",
  },
  {
    id: 12,
    formatted: "12",
  },
];

const quarterFilters = [
  {
    month: "Q1",
    id: 1,
    formatted: "Q1",
  },
  {
    month: "Q2",
    id: 2,
    formatted: "Q2",
  },
  {
    month: "Q3",
    id: 3,
    formatted: "Q3",
  },
  {
    month: "Q4",
    id: 4,
    formatted: "Q4",
  },
];

export {
  monthFilters,
  quarterFilters,
  COLORS,
  CALENDAR_COLORS,
  CUT_OFF,
  LOGO_DIMENSIONS,
};
