import React from "react";
import "./dashboard.css";
import { Controller, useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { Input, Button, List, Dialog } from "antd-mobile";
import Layout from "./Layout";
import { useIntl } from "react-intl";

const { REACT_APP_API_URL: API_URL } = process.env;

const axios = require("axios").default;

export default function ResetPassword() {
  const { resetUUID } = useParams();
  const history = useHistory();
  const { control, handleSubmit, errors } = useForm();
  const intl = useIntl();

  async function onReset({ password }) {
    await axios(
      {
        method: "post",
        url: `${API_URL}/accounts/change-password`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        data: { resetUUID, password },
      },
      []
    )
      .then(() => {
        Dialog.alert({
          content: intl.formatMessage({ id: "password-changed" }),
          closeOnMaskClick: true,
          confirmText: intl.formatMessage({ id: "close" }),
          onConfirm: () => history.push("/login"),
        });
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response.status === 400) {
            Dialog.alert({
              content: intl.formatMessage({ id: "too-weak-password" }),
              closeOnMaskClick: true,
              confirmText: intl.formatMessage({ id: "close" }),
            });
          } else {
            Dialog.alert({
              content: intl.formatMessage({ id: "generic-error" }),
              closeOnMaskClick: true,
              confirmText: intl.formatMessage({ id: "close" }),
            });
          }
        }
      });
  }

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          margin: 10,
          padding: 10,
          borderRadius: 10,
          marginTop: 50,
        }}
      >
        <div>
          <form onSubmit={handleSubmit(onReset)}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Controller
                as={<Input type="password" />}
                style={{
                  background: "white",
                  margin: 10,
                  padding: 10,
                  border: "1px solid #eeeeee",
                  borderRadius: 10,
                }}
                name="password"
                control={control}
                defaultValue=""
                placeholder={intl.formatMessage({ id: "password-placeholder" })}
                rules={{
                  required: true,
                  minLength: 8,
                }}
              />
              {errors.password &&
                errors.password.type === "required" &&
                intl.formatMessage({ id: "password-required" })}
              });
              {errors.password &&
                errors.password.type === "minLength" &&
                intl.formatMessage({ id: "password-length" })}
              });
              <Button type="submit">
                {intl.formatMessage({ id: "password-reset" })}
              </Button>
              <div
                style={{
                  background: "white",
                  margin: 10,
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <List>
                  <List.Item style={{ fontSize: 12 }}>
                    {intl.formatMessage({ id: "password-length" })}}
                  </List.Item>
                  <List.Item style={{ fontSize: 12 }}>
                    {intl.formatMessage({ id: "password-digits" })}}
                  </List.Item>
                  <List.Item style={{ fontSize: 12 }}>
                    {intl.formatMessage({ id: "password-similar" })}}
                  </List.Item>
                  <List.Item style={{ fontSize: 12 }}>
                    {intl.formatMessage({ id: "password-popular" })}}
                  </List.Item>
                </List>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}
