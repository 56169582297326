import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { IntlWrapper } from "./IntlUtils.js";
import RequestInvoiceBookingInput from "./RequestInvoiceBookingInput";
import RequestInvoiceTypeSelect from "./RequestInvoiceTypeSelect";
import RequestInvoice from "./RequestInvoice";
import Dashboard from "./Dashboard";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import { ConfigProvider } from "antd";
import { COLORS } from "./constants";

const dayjs = require("dayjs");

dayjs().format();

const { REACT_APP_BRAND_NAME } = process.env;

function App() {
  const location = useLocation();

  function checkAuth() {
    if (localStorage.getItem("token") && localStorage.getItem("expiry")) {
      const today = dayjs();
      const expiry = localStorage.getItem("expiry");
      return today.isBefore(expiry);
    }
    return false;
  }

  return (
    <IntlWrapper>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: COLORS[REACT_APP_BRAND_NAME],
            colorInfo: COLORS[REACT_APP_BRAND_NAME],
            borderRadius: 2,
          },
        }}
      >
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <Route
            path="/login"
            render={(routeProps) => <Login routeProps={routeProps} />}
          />
          <Route
            path="/reset/:resetUUID"
            render={(routeProps) => <ResetPassword {...routeProps} />}
          />
          <Route
            path="/dashboard/:rentalId/:landlordId/:yearFilter/:monthFilter"
            render={(routeProps) =>
              !checkAuth() ? (
                <Redirect
                  to={{ pathname: "/login", state: { from: location } }}
                />
              ) : (
                <Dashboard {...routeProps} />
              )
            }
          />
          <Route
            path="/dashboard"
            render={(routeProps) =>
              !checkAuth() ? (
                <Redirect
                  to={{ pathname: "/login", state: { from: location } }}
                />
              ) : (
                <Dashboard {...routeProps} />
              )
            }
          />
          <Route
            exact
            path="/request-invoice"
            render={(routeProps) => (
              <RequestInvoiceBookingInput {...routeProps} />
            )}
          />
          <Route
            exact
            path="/request-invoice/:bookingsync_booking_id(\d+)/"
            render={(routeProps) => (
              <RequestInvoiceTypeSelect {...routeProps} />
            )}
          />
          <Route
            exact
            path="/request-invoice/:bookingsync_booking_id(\d+)/:type(company|person)"
            render={(routeProps) => <RequestInvoice {...routeProps} />}
          />
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        </Switch>
      </ConfigProvider>
    </IntlWrapper>
  );
}

export default App;
