import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Button from "antd/es/button";
import { logOut } from "./Dashboard";
import { LocaleSwitcher } from "./IntlUtils";
import "./utils.css";
import { useIntl, FormattedMessage } from "react-intl";
import { COLORS, LOGO_DIMENSIONS } from "./constants";

const { REACT_APP_BRAND_NAME } = process.env;

function Header({ type, isLoggedIn, nav, renderLocaleSwitcher }) {
  const intl = useIntl();

  const leftFlex = type === "center" ? 1 : 0.05;
  const rightFlex = isLoggedIn || renderLocaleSwitcher ? 0.15 : 0;
  const midFlex = 1 - leftFlex - rightFlex;

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: leftFlex,
          alignItems: "center",
          justifyContent: type === "center" ? "center" : null,
        }}
      >
        <Link to="/">
          <img
            alt="Logo"
            src={`/${REACT_APP_BRAND_NAME}.png`}
            style={{
              height: LOGO_DIMENSIONS[REACT_APP_BRAND_NAME].height,
              width: LOGO_DIMENSIONS[REACT_APP_BRAND_NAME].width,
            }}
          />
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          flex: midFlex,
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {nav &&
          nav.map((item, index) => (
            <div
              className="detail-button div-button"
              style={{
                paddingRight: 20,
                paddingLeft: 20,
                fontSize: 14,
                fontWeight: "bold",
                color: COLORS[REACT_APP_BRAND_NAME],
              }}
              key={index}
            >
              {item}
            </div>
          ))}
      </div>
      <div
        style={{
          display: "flex",
          flex: rightFlex,
          justifySelf: "flex-end",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {renderLocaleSwitcher && <LocaleSwitcher />}
        {isLoggedIn && (
          <Button
            style={{
              background: COLORS[REACT_APP_BRAND_NAME],
              borderColor: COLORS[REACT_APP_BRAND_NAME],
              fontSize: 12,
              fontWeight: "bold",
              marginLeft: 10,
            }}
            className="logout-button"
            type="primary"
            onClick={() => logOut(intl, false)}
          >
            <FormattedMessage id="log-out" />
          </Button>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  type: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
};

Header.defaultProps = {
  type: "center",
  children: null,
  renderLocaleSwitcher: false,
};

export default Header;
