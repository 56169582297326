import React from "react";
import { useIntl } from "react-intl";
import { sumByKey } from "./utils";

import Table from "antd/es/table";
import Empty from "antd/es/empty";

const { Column } = Table;

const dayjs = require("dayjs");

dayjs().format();

export default function ReservationsTable({
  bookings,
  columns,
  commission_ratio,
}) {
  const intl = useIntl();

  const renderColumn = (key, index) => {
    return (
      <Column
        key={`${key}-${index}`}
        title={
          key === "commission" && commission_ratio !== "1.0"
            ? `${intl.formatMessage({ id: key })} (${
                Number(commission_ratio) * 100
              }%)`
            : intl.formatMessage({ id: key })
        }
        dataIndex={key}
      />
    );
  };

  return bookings &&
    bookings.length !== 0 &&
    columns &&
    columns.length !== 0 ? (
    <>
      <Table
        dataSource={bookings}
        rowKey="id"
        size="small"
        pagination={false}
        summary={(pageData) => {
          let sums = [];
          columns.map((key) => {
            if (
              [
                "comments",
                "portal",
                "extras_description",
                "people",
                "created_date",
              ].includes(key)
            ) {
              sums.push({
                sum: "-",
                key: key,
              });
            } else if (
              key !== "from_date" &&
              key !== "until_date" &&
              key !== "id"
            ) {
              sums.push({
                sum: sumByKey(pageData, key),
                key: key,
              });
            }
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <b>{intl.formatMessage({ id: "bookings-sum" })}</b>
                </Table.Summary.Cell>
                {sums.map((sum) => (
                  <Table.Summary.Cell key={sum.key}>
                    <b>{sum.sum}</b>
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </>
          );
        }}
      >
        {columns
          .filter((column) => column !== "id")
          .map((column, index) => renderColumn(column, index))}
      </Table>
    </>
  ) : (
    <Empty
      className="empty-sign"
      description={intl.formatMessage({ id: "no-bookings" })}
    />
  );
}
