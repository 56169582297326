import React from "react";
import Empty from "antd/es/empty";
import Descriptions from "antd/es/descriptions";
import { useIntl } from "react-intl";

export default function RentalInfo({ rental }) {
  const intl = useIntl();

  return rental && rental.status ? (
    <div style={{ maxWidth: 750 }}>
      <br />
      <Descriptions
        title={`${intl.formatMessage({ id: "rental-info" })} ${rental.name}`}
        layout="horizontal"
        column={1}
        bordered
      >
        {rental.status && (
          <Descriptions.Item
            label={intl.formatMessage({ id: "rental-status" })}
          >
            {rental.status}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  ) : (
    <Empty
      className="empty-sign"
      description={intl.formatMessage({ id: "no-rental-info" })}
    />
  );
}
