import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, Button, Dialog } from "antd-mobile";
import { genericError } from "./Dashboard.js";
import { useHistory } from "react-router-dom";
import Layout from "./Layout";
import { useIntl, FormattedMessage } from "react-intl";

const { REACT_APP_API_URL: API_URL } = process.env;

const axios = require("axios").default;

const dayjs = require("dayjs");

dayjs().format();

export function wrongCredentials(intl) {
  Dialog.alert({
    content: intl.formatMessage({ id: "wrong-credentials" }),
    closeOnMaskClick: true,
    confirmText: intl.formatMessage({ id: "close" }),
  });
}

export function maintenance(intl) {
  Dialog.alert({
    content: intl.formatMessage({ id: "maintenance" }),
    closeOnMaskClick: true,
    confirmText: intl.formatMessage({ id: "close" }),
  });
}

export default function Login(props) {
  const { handleSubmit, errors, control } = useForm();
  const [isLogin, setIsLogin] = useState(true);
  const history = useHistory();
  const intl = useIntl();

  async function onLogin({ username, password }) {
    await axios(
      {
        method: "post",
        url: `${API_URL}/api/token/`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        data: { username, password },
      },
      []
    )
      .then((response) => {
        if (response && response.data.access) {
          if (response.data.disabled) {
            maintenance(intl);
            return;
          }

          localStorage.setItem("token", response.data.access);
          // set expiry to match backend token duration minus a minute to be safe
          const expiry = dayjs().add(23, "hour").add(59, "minute").format();
          localStorage.setItem("expiry", expiry);
          localStorage.setItem("role", response.data.role);

          const redirectTo = props.routeProps.location?.state?.from?.pathname;
          if (redirectTo) {
            history.push(redirectTo);
          } else {
            history.push("/dashboard");
          }
        }
      })
      .catch((error) =>
        error.response && error.response.status && error.response.status === 401
          ? wrongCredentials(intl)
          : genericError(intl)
      );
  }

  async function onReset({ username }) {
    await axios(
      {
        method: "post",
        url: `${API_URL}/accounts/reset-password`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        data: { username },
      },
      []
    )
      .then(() => window.location.reload(false))
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status !== 200
        ) {
          genericError();
        }
      });
  }

  return (
    <Layout isLoggedIn={false} renderLocaleSwitcher={true}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          background: "white",
          margin: 10,
          padding: 10,
          borderRadius: 10,
          marginTop: 50,
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
              border: "1px solid #eeeeee",
              borderRadius: 10,
            }}
          >
            <form onSubmit={handleSubmit(isLogin ? onLogin : onReset)}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <>
                  <Controller
                    as={<Input />}
                    style={{
                      background: "white",
                      margin: 10,
                      padding: 10,
                      border: "1px solid #eeeeee",
                      borderRadius: 10,
                    }}
                    name="username"
                    control={control}
                    defaultValue=""
                    placeholder={intl.formatMessage({ id: "username" })}
                    rules={{
                      required: true,
                    }}
                  />
                  {errors.username &&
                    intl.formatMessage({ id: "field-required" })}
                  {isLogin && (
                    <>
                      <Controller
                        as={<Input type="password" />}
                        style={{
                          background: "white",
                          margin: 10,
                          padding: 10,
                          border: "1px solid #eeeeee",
                          borderRadius: 10,
                        }}
                        name="password"
                        control={control}
                        defaultValue=""
                        placeholder={intl.formatMessage({ id: "password" })}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.password &&
                        intl.formatMessage({ id: "field-required" })}
                    </>
                  )}

                  <Button style={{ width: "100%", margin: 10 }} type="submit">
                    {isLogin
                      ? intl.formatMessage({ id: "log-in" })
                      : intl.formatMessage({ id: "reset-password" })}
                  </Button>
                </>
              </div>
            </form>
          </div>
          <div style={{ marginTop: 20 }}>
            {isLogin ? (
              <Button onClick={() => setIsLogin(false)}>
                <FormattedMessage id="password-reset" />
              </Button>
            ) : (
              <Button onClick={() => setIsLogin(true)}>
                <FormattedMessage id="back-to-login" />
              </Button>
            )}
          </div>
        </>
      </div>
    </Layout>
  );
}
