import React from "react";
import Button from "antd/es/button";
import Select from "antd/es/select";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { range } from "underscore";
import { monthFilters, quarterFilters, CUT_OFF } from "./constants";
import { Period } from "./utils";
import { useIntl } from "react-intl";

const { Option } = Select;

const { REACT_APP_BRAND_NAME } = process.env;

const dayjs = require("dayjs");

dayjs().format();

function PeriodSelect({ period, onSelect, onCheck, settlement_period }) {
  const intl = useIntl();

  const onPrev = () => onSelect(period.prev());
  const onNext = () => onSelect(period.next());

  const prevAvailable = onCheck(period.prev());
  const nextAvailable = onCheck(period.next());

  function renderMonthFilter() {
    if (settlement_period === "QUARTERLY") {
      return quarterFilters.map((quarter) => (
        <Option key={quarter.id} value={quarter.formatted}>
          {quarter.month}
        </Option>
      ));
    }

    return monthFilters.map((month, index) => (
      <Option key={month.id} value={month.formatted}>
        {intl.formatMessage({ id: `month-${index + 1}` })}
      </Option>
    ));
  }

  function renderYearFilter() {
    if (settlement_period === "QUARTERLY") {
      return range(
        CUT_OFF[REACT_APP_BRAND_NAME]["QUARTERLY"]["year"],
        dayjs().year() + 2
      ).map((year) => <Option key={year}>{year}</Option>);
    }

    return range(
      CUT_OFF[REACT_APP_BRAND_NAME]["MONTHLY"]["year"],
      dayjs().year() + 2
    ).map((year) => <Option key={year}>{year}</Option>);
  }

  function onDropdownSelect(year, month) {
    onSelect(Period.fromString(`${year}/${month}`));
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: 20,
      }}
    >
      <Button onClick={onPrev} disabled={!prevAvailable}>
        <LeftOutlined />
      </Button>
      <Select
        value={period.year}
        style={{ width: 120 }}
        onChange={(year) => onDropdownSelect(year, period.month.formatted)}
      >
        {renderYearFilter()}
      </Select>
      <Select
        value={
          settlement_period === "QUARTERLY"
            ? period.month.month
            : intl.formatMessage({ id: `month-${period.month.id}` })
        }
        style={{ width: 120 }}
        onChange={(month) => onDropdownSelect(period.year, month)}
      >
        {renderMonthFilter()}
      </Select>
      <Button onClick={onNext} disabled={!nextAvailable}>
        <RightOutlined />
      </Button>
    </div>
  );
}

export default PeriodSelect;
